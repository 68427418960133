// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[production]7.0-11505(ec009c668e)-C10/04/2025-10:30:26-B10/04/2025-10:35:09' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[production]7.0-11505(ec009c668e)-C10/04/2025-10:30:26-B10/04/2025-10:35:09",
  branch: "production",
  latestTag: "7.0",
  revCount: "11505",
  shortHash: "ec009c668e",
  longHash: "ec009c668eab7ed58a3b9860f6e1e682fe1bd7ad",
  dateCommit: "10/04/2025-10:30:26",
  dateBuild: "10/04/2025-10:35:09",
  buildType: "Ansible",
  } ;
